/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

module.exports.onClientEntry = () => {
  if (typeof window.IntersectionObserver === 'undefined') {
    return import('intersection-observer');
  }

  return Promise.resolve();
};
